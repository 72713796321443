import React, { useEffect, useState } from 'react'
import Location from '../../../../../assets/images/applicants/landing_page/location.svg'
import DownloadQR from '../../../../../assets/images/applicants/landing_page/download-app-qr.webp'
import { SignUpForm } from '../Forms/SignUpForm'
import { SignUpSteps } from '../SignUpSteps'
import { HeroTypes } from './types'


export const SignUpHero = ({
  title,
  subtitle,
  content,
  currentCity,
  currentCountrySlug,
  currentCountry,
  currentZip,
  countryPlaceholders,
  signupFormSubmitUrl,
  sendAppLinkUrl,
  signUpSteps,
  shouldShowTwoWheelerPromotion,
  referralCode,
  isFormOpen,
}: HeroTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(isFormOpen)
  const [hasApplicantSignedUp, setHasApplicantSignedUp] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(isFormOpen)
  }, [isFormOpen])

  return (
    <>
      <div className="jobs-hero lg:max-w-7xl px-7 xl:px-0 py-10 lg:pb-24 lg:pt-0 m-auto text-kale text-base lg:text-xl flex flex-col lg:flex-row items-start justify-between">
        <div>
          {currentCity && <span className="flex"><img src={Location} alt="green location marker" className="mr-2.5" />{currentCity}</span>}
          <h1 className="text-mxl lg:text-7xl font-medium font-heading mt-4 lg:mt-9 mb-5 lg:mb-9 lg:max-w-2xl lg:pr-8">
            {hasApplicantSignedUp ? signUpSteps.downloadAppTitle : title.replace('%{state}', currentCity)}
          </h1>
          <h4 className="mb-4 font-normal text-xl lg:text-2xl lg:max-w-xl" id="shoppers-signup-form">
            {hasApplicantSignedUp ? signUpSteps.downloadAppSubtitle : subtitle}
          </h4>
          {isOpen ? (
            <SignUpForm
              content={content}
              country={currentCountry}
              countrySlug={currentCountrySlug}
              city={currentCity}
              countryPlaceholders={countryPlaceholders}
              currentZip={currentZip}
              referralCode={referralCode}
              shouldShowTwoWheelerPromotion={shouldShowTwoWheelerPromotion}
              submitUrl={signupFormSubmitUrl}
              sendAppLinkUrl={sendAppLinkUrl}
              hasApplicantSignedUp={hasApplicantSignedUp}
              setHasApplicantSignedUp={setHasApplicantSignedUp}
            />
          ) : (
            <button
              className="rounded-full bg-kale text-cashew my-4 lg:my-12 py-3 px-7 text-base w-full md:w-32"
              onClick={() => setIsOpen(true)}
            >
              Sign up
            </button>
          )}
        </div>
        {hasApplicantSignedUp ? (
          <img src={DownloadQR} alt="Download app QR code" className="hidden md:block max-w-xs w-full mt-5 lg:my-auto" />
        ) : (
          <div className="signup-hero__hero-wrapper">
            <img
              className="signup-hero__background-image"
              src="/landing_page/images/shoppers-hero-animated.webp"
              width="504"
              height="504"
              alt="women shopping"
            />
            <img
              className="signup-hero__pricebar"
              src="/landing_page/images/shoppers-hero-pricebar-animated.webp"
              width="271"
              height="375"
              alt="pricebar highlighting deliveries"
            />
          </div>
        )}
      </div>
      <div className="bg-cashew-dark px-7 xl:px-0">
        <SignUpSteps content={signUpSteps} hasApplicantSignedUp={hasApplicantSignedUp} />
      </div>
    </>
  )
}
