import React, { useState, useEffect } from 'react'
import { TextInputTypes } from './types'

export const TextInput = ({
  className,
  label,
  name,
  tabIndex,
  placeholder,
  required,
  value,
  onChange,
  requiredMessage,
}: TextInputTypes) => {
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>()

  const handleBlur = () => {
    if ((!value || value.length === 0) && required) {
      setValidationErrorMessage(requiredMessage)
    } else {
      setValidationErrorMessage(undefined)
    }
  }

  return (
    <div className="flex flex-col mb-12">
      {label && <label htmlFor={name} className="text-base font-medium">{label}</label>}
      <input
        className={`${className}`}
        type="text"
        name={name}
        value={value}
        tabIndex={tabIndex}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={onChange}
        required={required}
      />
      {validationErrorMessage && (
        <p className="text-cinnamon text-base py-4">{validationErrorMessage}</p>
      )}
    </div>
  )
}
