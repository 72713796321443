import React, { useState } from 'react';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import { postData } from '../../../../../api_utils/utils'
import { PhoneInputField } from '../Inputs/PhoneInput'
import { TextInput } from '../Inputs/TextInput'
import { EmailInput } from '../Inputs/EmailInput'
import { ZipInput } from '../Inputs/ZipInput'
import { DownloadAppForm } from '../DownloadAppForm';
import PlusIcon from '../../../../../../assets/images/applicants/landing_page/plus-icon-kale.svg'

export const SignUpForm = ({
  content: {
    labels,
    placeholders,
    validations
  },
  currentZip,
  countryPlaceholders,
  city,
  country,
  countrySlug,
  referralCode,
  shouldShowTwoWheelerPromotion,
  submitUrl,
  sendAppLinkUrl,
  hasApplicantSignedUp,
  setHasApplicantSignedUp
}) => {
  const currentZipPlaceholder = countryPlaceholders[countrySlug].placeholder
  const zipCodeMask = countryPlaceholders[countrySlug].mask

  const [isReferralInputVisible, setIsReferralInputVisible] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    zip: currentZip || '',
    country: country || 'US',
    referralCode: referralCode || '',
  })

  const [formError, setFormError] = useState({
    email: '',
    phone: '',
    zip: ''
  })

  const handleSuccess = (applicant, payload) => {
    setHasApplicantSignedUp(true)
    window.scrollTo(0, 0);

    if (applicant && applicant.spuid) {
      window.dataLayer?.push({
        event: 'signup',
        page_type: 'download_app',
        source: {
          Url: window.location.pathname,
        },
        sp_workflow_state: 'download_app',
        spuid: applicant.spuid
      })
    }
  }

  const formSubmitEventPayload = () => {
    return {
      'First Name': form.firstName,
      'Last Name': form.lastName,
      Phone: formatPhoneNumber(form.phone),
      Email: form.email,
      Zipcode: form.zip,
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setIsSubmitting(true)
    setFormError({ email: '', phone: '', zip: '' })

    postData(submitUrl, {
      applicant: {
        first_name: form.firstName,
        last_name: form.lastName,
        country_picker: form.country,
        email: form.email,
        phone: formatPhoneNumber(form.phone),
        zip_code: form.zip,
        referer: form.referralCode,
      },
      city,
    })
      .then((applicant) => handleSuccess(applicant, formSubmitEventPayload()))
      .catch(({ email = [], phone = [], zip_code: zipCode = [] }) => {
        const emailErrorMsg = email[0]
        const phoneErrorMsg = phone[0]
        const zipCodeErrorMsg = zipCode[0] ? validations.invalidZip : ''

        setFormError({ email: emailErrorMsg, phone: phoneErrorMsg, zip: zipCodeErrorMsg })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handlePhoneChange = (phone) => {
    setForm(prevState => ({ ...prevState, phone }))
  }

  const handleChange = (event) => {
    setForm(prevState => ({ ...prevState, [event.target.name]: event.target.value }))
  }

  return (
    <>
      {hasApplicantSignedUp ?
        (
          <DownloadAppForm
            sendAppLinkUrl={sendAppLinkUrl}
            invalidPhoneMessage={validations.invalidPhone}
            successMessage={validations.successMessage}
            requiredMessage={validations.required}
            autofillPhone={form.phone}
            primaryColorCashew
          />
        ) : (
          <form className="signup-form max-w-2xl mt-12" onSubmit={handleSubmit}>
            <div>
              <TextInput
                className="text-kale bg-cashew px-0.5 py-4 border-b border-kale-light placeholder:text-cashew-dark focus:border-kale-dark"
                label={labels.firstName}
                name="firstName"
                placeholder={placeholders.firstName}
                value={form.firstName}
                onChange={handleChange}
                requiredMessage={validations.required}
                required
              />
              <TextInput
                className="text-kale bg-cashew px-0.5 py-4 border-b border-kale-light placeholder:text-cashew-dark focus:border-kale-dark"
                label={labels.lastName}
                name="lastName"
                placeholder={placeholders.lastName}
                value={form.lastName}
                onChange={handleChange}
                requiredMessage={validations.required}
                required
              />
            </div>
            <EmailInput
              className="text-kale bg-cashew px-0.5 py-4 border-b border-kale-light placeholder:text-cashew-dark focus:border-kale-dark"
              name="email"
              label={labels.email}
              placeholder={placeholders.email}
              value={form.email}
              validationMsg={formError.email}
              onChange={handleChange}
              requiredMessage={validations.required}
              required
            />
            <div className="mb-12">
              <PhoneInputField
                className="text-kale bg-cashew px-0.5 py-4 border-b border-kale-light placeholder:text-cashew-dark focus:border-kale-dark"
                type="tel"
                label={labels.phone}
                name="phone"
                placeholder={placeholders.phone}
                value={form.phone}
                setValue={handlePhoneChange}
                validationMsg={formError.phone}
                requiredMessage={validations.required}
                invalidPhoneMessage={validations.invalidPhone}
                required
                country={country.toUpperCase()}
              />
            </div>

            {!currentZip && (
              <div className="flex-container">
                <ZipInput
                  className="text-kale bg-cashew px-0.5 py-4 border-b border-kale-light placeholder:text-cashew-dark focus:border-kale-dark"
                  name="zip"
                  label={labels.zip}
                  country={country}
                  value={form.zip}
                  validationMsg={formError.zip}
                  onChange={handleChange}
                  currentZipPlaceholder={placeholders.zip}
                  zipCodeMask={zipCodeMask}
                  required
                />
              </div>
            )}
            {!referralCode && (
              <div className="">
                {isReferralInputVisible ? (
                  <TextInput
                    className="text-kale bg-cashew px-0.5 py-4 border-b border-kale-light placeholder:text-cashew-dark focus:border-kale-dark"
                    name="referralCode"
                    placeholder={labels.referralCode}
                    value={form.referralCode}
                    onChange={handleChange}
                  />
                ) : (
                  <div
                    className="mb-12 flex"
                    role="button"
                    onClick={() => setIsReferralInputVisible(prevState => !prevState)}
                    onKeyPress={() => setIsReferralInputVisible(prevState => !prevState)}
                  >
                    <img src={PlusIcon} width="16" height="16" alt="show referral code text input" className="mr-3" />
                    <span className="text-base font-medium">{labels.referralCodeAdd}</span>

                  </div>
                )}
              </div>
            )}
            <div className="">
              <p className="text-sm" dangerouslySetInnerHTML={{ __html: labels.legalHtml }} />
              <p className="text-sm mt-2">{labels.moreLegal}</p>
              {shouldShowTwoWheelerPromotion && (
                <p
                  className="text-sm mt-2"
                  dangerouslySetInnerHTML={{ __html: labels.twoWheelerPromotion }}
                />
              )}
            </div>
            <button
              className="rounded-full bg-kale text-cashew my-12 py-3 px-7 text-base"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? labels.submitting : labels.submit}
            </button>
          </form>
        )}
    </>
  )
}
