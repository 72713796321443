import * as React from 'react'
import { Navbar } from './Navbar'
import { Footer } from './Footer'

export const LandingPagesLayout = ({
  children,
  signupLink,
  footerContent,
  currentHost,
  topCities,
  privacyChoicesEnabled,
  currentCountry,
  locale,
  className
}) => (
  <>
    <Navbar onClickExpandSignupForm={signupLink} currentHost={currentHost} />
    <div className={`w-full lg:max-w-7xl m-auto pt-20 sm:pt-0 ${className}`}>
      {children}
    </div>
    <Footer
      content={footerContent}
      currentHost={currentHost}
      topCities={JSON.parse(topCities)}
      privacyChoicesEnabled={privacyChoicesEnabled}
      currentCountry={currentCountry}
      locale={locale}
    />
  </>
)

