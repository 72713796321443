import React from 'react'
import HeroImg from '../../../../../assets/images/applicants/landing_page/shopper-jobs-become-shopper.jpg'

export const BecomeAShopper = ({
  title,
  subtitle,
  cta,
  signupUrl
}: {
  title: string;
  subtitle: string;
  cta: string;
  signupUrl: string
}) => {
  return (
    <div className="jobs-flex lg:max-w-7xl m-auto py-14 lg:pb-24 lg:pt-32 flex flex-col lg:flex-row items-start justify-between text-cashew font-normal">
      <div>
        <h2 className="font-heading text-hxl md:text-h2xl font-medium lg:mr-28 mb-4 lg:mb-6">{title}</h2>
        <p className="text-lg mb-2 lg:mb-4">{subtitle}</p>
        <a href={signupUrl} className="inline-block bg-cashew text-kale rounded-full px-7 py-3 mt-5 lg:mt-9">{cta}</a>
      </div>
      <img src={HeroImg} alt="Shopper Jobs" className="max-w-2xl w-full mt-9 lg:mt-0 rounded-18" />
    </div>
  )
}
