import React from 'react'
import AndrewS from '../../../../../assets/images/applicants/landing_page/why-shoppers-pp-0.jpg'
import JocelynP from '../../../../../assets/images/applicants/landing_page/why-shoppers-pp-1.jpg'
import RaynahW from '../../../../../assets/images/applicants/landing_page/why-shoppers-pp-2.jpg'
import SeleniaM from '../../../../../assets/images/applicants/landing_page/why-shoppers-pp-3.jpg'

export type ShopperTypes = {
  name: string;
  location: string;
}

export type ShoppersTypes = {
  header: string;
  quote: string;
  shopper: ShopperTypes;
}

const imageKey = {
  'Andrew S.': AndrewS,
  'Jocelyn P.': JocelynP,
  'Raynah W.': RaynahW,
  'Selenia M.': SeleniaM
}

const Shopper = ({ header, quote, shopper, index }: {
  header: string;
  quote: string;
  shopper: ShopperTypes
  index: number;
}) => {
  return (
    <section className={`flex flex-col rounded-lg px-7 py-8 bg-cashew-dark w-1/4 min-w-shopper ${header !== 'Support' ? 'mr-4' : 'mr-0'}`}>
      <h3 className="text-sm lg:text-lg mb-4 font-medium">{header}</h3>
      <p className="font-contrast text-2xl lg:text-mlg mt-3 mb-9" dangerouslySetInnerHTML={{ __html: quote }} />
      <div className="flex flex-row items-center mt-auto">
        <img
          className="rounded-full w-14 h-14"
          alt={`${shopper.name} profile`}
          src={imageKey[shopper.name]}
        />
        <div className="flex flex-col ml-5">
          <h3 className="text-sm lg:text-lg font-medium">{shopper.name}</h3>
          <p className="text-xs lg:text-sm">{shopper.location}</p>
        </div>
      </div>
    </section>
  )
}

export const WhyShoppersChooseInstacart = ({
  title,
  description,
  shoppers
}: {
    title: string;
    description: string;
    shoppers: ShoppersTypes[]
  }) => {
  return (
    <section className="px-7 xl:px-0">
      <div className="lg:max-w-7xl m-auto py-14 lg:py-32 mx-auto text-kale">
        <h1 className="font-heading text-hxl lg:text-h2xl">{title}</h1>
        <p className="text-xl mt-4 md:mt-6" dangerouslySetInnerHTML={{ __html: description }} />
        <div className="mt-9 lg:mt-16 flex flex-row overflow-scroll">
          {shoppers.map(({ header, quote, shopper }, index) =>
            (<Shopper
              header={header}
              quote={quote}
              shopper={shopper}
              key={index}
              index={index}
            />)
          )}
        </div>
      </div>
    </section>
  )
}
