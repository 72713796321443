import React, { useState, useCallback } from 'react';

function SignupListSection({ item, number, hasApplicantSignedUp }) {
  return (
    <div className="w-full flex flex-row md:flex-col mb-6 items-center">
      <div
        className="z-10 flex flex-shrink-0 items-center justify-center text-2xl md:text-5xl border-2 border-solid border-kale rounded-full bg-cashew-dark w-10 h-10 md:w-20 md:h-20 p-1 box-content my-0 md:my-8 font-contrast"
      >
        {hasApplicantSignedUp && number === 1 ? <SuccessSvg /> : number}
      </div>
      <div className="md:flex-grow items-center flex-col max-w-xs ml-6 md:ml-0">
        <div className="max-w-md">
          <h4 className="text-left md:text-center text-base md:text-2xl mb-2 md:mb-4 font-medium">{item.title}</h4>
          <p className="text-left md:text-center text-xs md:text-lg">{item.description}</p>
        </div>
      </div>
    </div>
  );
}

const SuccessSvg = () => (
  <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="89" height="89" rx="44.5" fill="#003D29" />
    <path fillRule="evenodd" clipRule="evenodd" d="M38.25 54.728L28.073 44.55a3.875 3.875 0 10-5.48 5.48L35.51 62.949a3.875 3.875 0 005.48 0L69.4 34.53a3.875 3.875 0 10-5.481-5.48L38.25 54.729z" fill="#FAF1E5" />
    <rect x="1" y="1" width="89" height="89" rx="44.5" stroke="#003D29" strokeWidth="2" />
  </svg>
)

function CaretDownIcon() {
  return (
    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5711 9.07151L8.5 2.00044L1.42893 9.07151" stroke="#003D29" strokeWidth="2" />
    </svg>
  );
}

function CaretUpIcon() {
  return (
    <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5711 1.07107L8.5 8.14214L1.42893 1.07107" stroke="#003D29" strokeWidth="2" />
    </svg>
  );
}


export const SignUpSteps = ({ content: { title, body }, hasApplicantSignedUp }) => {
  const [isOpen, setIsOpen] = useState<boolean>(hasApplicantSignedUp || true)

  return (
    <section className="lg:max-w-7xl m-auto py-2">
      <div id="sign-up-steps" className="flex flex-col mx-auto py-6 gap-6 text-kale">
        <div role="button" onClick={() => setIsOpen(prevState => !prevState)}>
          <div className="flex flex-col items-center lg:py-6">
            <button>
              <p className="flex flex-row items-center text-xl font-medium">
                {title}
                <span className="ml-2">
                  {isOpen ? <CaretDownIcon /> : <CaretUpIcon />}
                </span>
              </p>
            </button>
            <div className="relative w-full">
              <div className={`${isOpen ? 'flex flex-col md:flex-row justify-between' : 'hidden'} mt-4`}>
                {body.map((item, i) => <SignupListSection key={i} item={item} number={i + 1} hasApplicantSignedUp={hasApplicantSignedUp} />)}
              </div>
              <div className={`${isOpen ? 'absolute' : 'hidden'} mx-px md:mx-40 lg:mx-48 w-0.5 md:w-auto h-3/4 md:h-0.5 bg-kale top-10 left-6 md:top-24 md:left-0 md:right-0`} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
