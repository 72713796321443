import React, { useState, useRef, MouseEventHandler, Ref } from 'react';
import ReactSVG from 'react-svg'
import Expand from '../../../../../assets/images/applicants/landing_page/expand.svg'
import Collapse from '../../../../../assets/images/applicants/landing_page/collapse.svg'

type QuestionType = {
  question: string;
  answer: string;
}

const Question = ({ question, onClick, isCollapsed, questionsRef, index }: {
  question: QuestionType;
  onClick: MouseEventHandler<HTMLDivElement>;
  isCollapsed: boolean;
  questionsRef: React.MutableRefObject<HTMLDivElement[]>;
  index: number;
}) => (
  <div className="relative border-t border-kale-light">
    <div className="flex justify-between items-center" onClick={onClick} role="button">
      <p className="my-7 lg:my-9 mr-3 font-medium">{question.question}</p>
      {!isCollapsed ? <ReactSVG path={Collapse} /> : <ReactSVG path={Expand} />}
    </div>
    <div className="overflow-hidden max-h-0" ref={el => { questionsRef.current[index + 1] = el }}>
      <p
        className="font-light mb-8 text-sm lg:text-lg"
        dangerouslySetInnerHTML={{ __html: question.answer }}
      />
    </div>
  </div>
)

export const CommonQuestions = ({ title, questions }: {title: string; questions: QuestionType[]}) => {
  const [isOpen, setIsOpen] = useState<number>(0)
  const questionsRef = useRef<HTMLDivElement[]>([])

  const openMenu = (selectedIndex: number) => {
    const item = questionsRef.current[selectedIndex]
    const h = item.scrollHeight === item.clientHeight ? '0' : item.scrollHeight
    setIsOpen(prevIndex => (prevIndex !== selectedIndex ? selectedIndex : 0))
    questionsRef.current.forEach((ref, menuRefIndex) => {
      menuRefIndex === selectedIndex ? (ref.style.maxHeight = `${h}px`) : (ref.style.maxHeight = '0px')
    });
  }

  return (
    <div id="faq" className="contract-body px-7 xl:px-0 py-10 lg:pb-24 lg:pt-32 m-auto lg:max-w-7xl text-kale text-base lg:text-xl">
      <h1 className="block text-hxl lg:text-h2xl font-heading mb-9 pb-6">{title}</h1>
      <div>
        {questions.map((question, i) => (
          <Question
            key={i}
            question={question}
            isCollapsed={isOpen !== i + 1}
            onClick={() => openMenu(i + 1)}
            questionsRef={questionsRef}
            index={i}
          />
        ))}
      </div>
    </div>
  )
}

