import React from 'react'
import ShopperLeft from '../../../../../assets/images/applicants/landing_page/shoppers-shopper-0.jpg'
import ShopperRight from '../../../../../assets/images/applicants/landing_page/shoppers-shopper-1.jpg'

const QuoteArrowRight = () => (
  <div className="w-12 flex-shrink-0 -ml-5">
    <svg width="100%" height="auto" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M56.9749 39.8143C61.0084 37.4194 61.0084 31.5805 56.9749 29.1856L9.33588 0.899991C5.21601 -1.54618 -2.09094e-06 1.42297 -1.67207e-06 6.21432L3.27355e-06 62.7856C3.69243e-06 67.577 5.21602 70.5461 9.33589 68.1L56.9749 39.8143Z" fill="#003020" />
    </svg>
  </div>
)
const QuoteArrowLeft = () => (
  <div className="w-12 flex-shrink-0 -mr-5">
    <svg width="100%" height="auto" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg" transform="scale(-1, 1)">
      <path fillRule="evenodd" clipRule="evenodd" d="M56.9749 39.8143C61.0084 37.4194 61.0084 31.5805 56.9749 29.1856L9.33588 0.899991C5.21601 -1.54618 -2.09094e-06 1.42297 -1.67207e-06 6.21432L3.27355e-06 62.7856C3.69243e-06 67.577 5.21602 70.5461 9.33589 68.1L56.9749 39.8143Z" fill="#003020" />
    </svg>
  </div>
)

const ShopperQuoteLeft = ({ shopper: { chat, name } }) => {
  return (
    <div className="flex items-center mb-6">
      <div className="bg-kale-dark rounded-lg p-6 lg:px-14 lg:py-10 flex flex-col relative">
        <p className="mb-4 text-sm lg:text-2xl">{chat}</p>
        <p className="text-xs lg:text-lg">{name}</p>
      </div>
      <QuoteArrowRight />
      <div className="ml-3 flex-shrink-0 rounded-full overflow-hidden w-16 h-16 lg:w-28 lg:h-28 flex items-center justify-center">
        <img src={ShopperLeft} alt={name} className="w-20 lg:w-32 lg:h-32 p-1" style={{ maxWidth: 'unset' }} />
      </div>
    </div>
  )
}

const ShopperQuoteRight = ({ shopper: { chat, name } }) => {
  return (
    <div className="flex items-center mt-6">
      <div className="mr-3 flex-shrink-0 rounded-full overflow-hidden w-16 h-16 lg:w-28 lg:h-28 flex items-center justify-center">
        <img src={ShopperRight} alt={name} className="w-20 lg:w-32 lg:h-32 p-1" style={{ maxWidth: 'unset' }} />
      </div>
      <QuoteArrowLeft />
      <div className="bg-kale-dark rounded-lg p-6 lg:px-14 lg:py-10 flex flex-col">
        <p className="mb-4 text-sm lg:text-2xl">{chat}</p>
        <p className="text-xs lg:text-lg">{name}</p>
      </div>
    </div>
  )
}

export const ShoppersMakeIt = ({
  title,
  description,
  shopperChat,
}: {
  title: string;
  description: string;
  shopperChat: {
    chat: string;
    name: string;
  }[]
}) => {
  return (
    <div className="jobs-flex lg:max-w-7xl m-auto pb-14 pt-10 lg:py-32 flex flex-col lg:flex-row items-start justify-between text-cashew font-normal">
      <div className="lg:w-2/5">
        <h2 className="font-contrast font-normal text-hxl md:text-h2xl lg:pr-40 mb-6">{title}</h2>
        <p className="text-lg lg:max-w-md mb-8">{description}</p>
      </div>
      <div className="lg:w-3/5">
        <ShopperQuoteLeft shopper={shopperChat[0]} />
        <ShopperQuoteRight shopper={shopperChat[1]} />
      </div>
    </div>
  )
}
