const ajax = (url, params) => fetch(url, params)
  .then(response => response.json()
    .then(json => (response.ok ? json : Promise.reject(json))));

const csrfToken = () => document.querySelector('meta[name="csrf-token"]').content;

const postData = (endpoint, data) => ajax(endpoint, {
  method: 'POST',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken(),
  },
  credentials: 'same-origin',
});

const postForm = (endpoint, formData, config) => fetch(endpoint, {
  method: 'POST',
  body: formData,
  headers: {
    'X-CSRF-Token': csrfToken(),
  },
  credentials: 'same-origin',
  ...config
})

export { ajax, csrfToken, postData, postForm };
