import React, { useRef, useState } from 'react';
import { ajax } from '../../../../api_utils/utils'

export type StoreSearchProps = {
  cta: string;
  subtext: {
    copy: string; cta: string
  };
  setStores: Function,
  setIsZipSearchSuccessful: Function
};

export const StoreSearch = ({
  cta,
  subtext,
  setStores,
  setIsZipSearchSuccessful
}: StoreSearchProps) => {
  const { currentCountrySlug, countryPlaceholders } = window.gon;
  const [error, setError] = useState<string | undefined>()
  const zipRef = useRef<HTMLInputElement>()

  const handleSubmit = (event) => {
    event.preventDefault()
    if (zipRef.current && zipRef.current.value) {
      ajax(`city_stores_lookup?zip=${zipRef.current.value}`)
        .then((res) => {
          console.info('res?', res)
          setIsZipSearchSuccessful(true)
          setStores(res.stores)
        })
        .catch((err) => {
          setError(err.error)
        })
    }
  }

  const scrollTo = (selector) => {
    document.querySelector(selector).scrollIntoView({
      behavior: 'smooth'
    })
  }

  return (
    <div className="mt-4 lg:mt-12 flex flex-col items-start">
      <form className="flex flex-wrap items-center justify-start text-sm lg:text-base" onSubmit={handleSubmit}>
        <input
          className="py-3 pl-4 pr-7 lg:pl-7 lg:pr-10 mr-3 mb-3 border border-kale-light bg-cashew rounded-full"
          type="text"
          name="zipcode"
          ref={zipRef}
          placeholder={countryPlaceholders[currentCountrySlug].placeholder}
        />
        <button className="py-3.5 px-6 lg:px-7 mb-3 bg-kale text-cashew rounded-full text-sm whitespace-nowrap" type="submit" value={cta}>{cta}</button>
      </form>
      {error && <p className="text-cinnamon text-sm py-2">{error}</p>}
      <div>
        <p className="mt-2 mb-6 lg:mt-5 lg:text-lg">{subtext.copy}
          <button onClick={() => scrollTo('#faq')} className="font-medium underline underline-offset-2 pl-1.5">{subtext.cta}</button>
        </p>
      </div>
    </div>
  );
};
