import React, { useState } from 'react'
import { PlaceholderStores } from './stores';
import { StoreSearch } from './StoreSearch';

export type StoreType = {
  name: string;
  street?: string;
  address?: string;
  city: string;
  zip: string;
  logo: string;
}

const testStores = [
  {
    name: 'Costco',
    street: '2655 El Camino Real',
    city: 'Tustin',
    state: 'CA',
    zip: '92782',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/5/65f2304b-908e-4cd0-981d-0d4e4effa8de.png'
  },
  {
    name: 'Costco',
    street: '115 Technology West Drive',
    city: 'Irvine',
    state: 'CA',
    zip: '92618',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/5/65f2304b-908e-4cd0-981d-0d4e4effa8de.png'
  },
  {
    name: 'Costco',
    street: '33961 Doheny Park Road',
    city: 'San Juan Capistrano',
    state: 'CA',
    zip: '92675',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/5/65f2304b-908e-4cd0-981d-0d4e4effa8de.png'
  },
  {
    name: 'Costco',
    street: '27972 Cabot Road',
    city: 'Laguna Niguel',
    state: 'CA',
    zip: '92677',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/5/65f2304b-908e-4cd0-981d-0d4e4effa8de.png'
  },
  {
    name: 'Costco',
    street: '33961 Doheny Park Road',
    city: 'San Juan Capistrano',
    state: 'CA',
    zip: '92675',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/5/65f2304b-908e-4cd0-981d-0d4e4effa8de.png'
  },
  {
    name: "Gelson's",
    street: '5521 Alton Parkway',
    city: 'Irvine',
    state: 'CA',
    zip: '92618',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/63/663af79d-8554-408b-8bb4-7372c974757a.png'
  },
  {
    name: "Gelson's",
    street: '24 Monarch Bay Plaza',
    city: 'Dana Point',
    state: 'CA',
    zip: '92629',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/63/663af79d-8554-408b-8bb4-7372c974757a.png'
  },
  {
    name: 'Total Wine & More',
    street: '2952 El Camino Real',
    city: 'Tustin',
    state: 'CA',
    zip: '92782',
    logo: 'https://shoppers.instacart.com/halvor/warehouse/logo/144/c42efdcb-47d4-4a4a-afb1-c1029be23af1.png'
  }
]

const StoresOnInstacart = ({ subtitle }: { subtitle: string }) => {
  return (
    <div className="w-full lg:w-3/5 grid grid-cols-3 lg:grid-cols-4 gap-x-4">
      <p className="col-span-full mb-2 lg:mb-9">{subtitle}</p>
      {PlaceholderStores.map((value, i) => {
        return (
          <div
            key={i}
            className="mx-auto my-5 w-24 h-24 md:w-36 md:h-36 bg-white rounded-full p-4 lg:p-6 flex items-center justify-center"
          >
            {value}
          </div>
        )
      })}
    </div>
  )
}

const ZipSearchStores = ({ storeResults }: { storeResults: StoreType[] }) => {
  return (
    <div className="w-full lg:w-3/5 grid grid-cols-1 lg:grid-cols-2 gap-7">
      {storeResults.map((value, i) => {
        const location = [value.street || value.address, value.city]
        location.filter(item => item)
        return (
          <div
            key={i}
            className="text-sm lg:text-lg w-full max-w-sm bg-cashew-dark rounded-lg p-4 lg:p-6 flex"
          >
            <img src={value.logo} alt={value.name} width="78" className="rounded-full" />
            <div className="ml-6">
              <p className="text-base font-medium">{value.name}</p>
              <p className="text-sm mt-1">{location.join(', ')}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const StoresYouCanShop = ({
  title,
  stores,
  search,
  subtitle,
  cta,
  subtext
}: {
  title: string;
  subtitle?: string;
  cta?: string;
  subtext?: {
    copy: string;
    cta: string;
  }
  stores: StoreType[];
  search?: boolean
}) => {
  const nearbyStores = stores.length ? stores : testStores
  const [isZipSearchSuccessful, setIsZipSearchSuccessful] = useState<boolean>(false)
  const [storeResults, setStoreResults] = useState<StoreType[]>(nearbyStores)

  return (
    <div className="jobs-flex lg:max-w-7xl m-auto px-7 xl:px-0 py-14 lg:py-24 flex flex-col lg:flex-row items-start justify-between text-kale font-normal">
      <div className="lg:w-2/5 lg:pr-24 mb-4">
        <h2 className="font-heading font-medium text-mlg md:text-hxl">{title}</h2>
        {search && <StoreSearch cta={cta} subtext={subtext} setStores={setStoreResults} setIsZipSearchSuccessful={setIsZipSearchSuccessful} />}
      </div>
      {search && !isZipSearchSuccessful ? (
        <StoresOnInstacart subtitle={subtitle} />
      ) : (
        <ZipSearchStores storeResults={storeResults.slice(0, 6)} />
      )}
    </div>
  )
}
