import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input/input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { PhoneInputTypes } from './types'

const PHONE_MAX_LENGTH = 15 // (xxx) xxx-xxxx

export const PhoneInputField = ({
  tabIndex,
  className,
  type,
  label,
  name,
  placeholder,
  required,
  value,
  setValue,
  requiredMessage,
  invalidPhoneMessage,
  validationMsg,
  country = 'US',
  setIsValid = (flag) => {}
}: PhoneInputTypes) => {
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(validationMsg)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event === 'undefined') {
      return
    }
    const isValidNumber = isValidPhoneNumber(event)
    setIsValid(isValidNumber)
    setValue(event)
  }

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { validationMessage } = event.target

    if ((!value || value.length === 0) && required) {
      setValidationErrorMessage(requiredMessage)
      return
    }
    const isValidNumber = isValidPhoneNumber(value)
    setIsValid(isValidNumber)

    if (type === 'tel' && !isValidNumber) {
      validationMessage = invalidPhoneMessage
    }

    if (validationMessage) {
      setValidationErrorMessage(validationMessage)
    } else {
      setValidationErrorMessage(undefined)
    }
  }

  useEffect(() => {
    setValidationErrorMessage(validationMsg)
  }, [validationMsg])

  return (
    <div className="flex flex-col">
      {label && <label htmlFor={name} className="text-base font-medium">{label}</label>}
      <PhoneInput
        tabIndex={tabIndex}
        className={`py-3 ${className}`}
        name={name}
        defaultCountry="US"
        country={country}
        placeholder={placeholder}
        value={value}
        smartCaret={false}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={PHONE_MAX_LENGTH}
      />
      {validationErrorMessage && (
        <p className="text-sm text-cinnamon py-4">{validationErrorMessage}</p>
      )}
    </div>
  )
}
