import React, { useState, useEffect } from 'react'
import ReactSVG from 'react-svg'
import ShopperIcon from '../../../../../assets/images/applicants/shoppericon.svg'
import InstaIconKale from '../../../../../assets/images/applicants/landing_page/instacart-logo-kale.svg'
import { trackButtonPress } from '../../../../utils/analytics'

export const Navbar = ({
  onClickExpandSignupForm,
  currentHost,
}: {
  onClickExpandSignupForm: () => void;
  currentHost: string;
}) => {
  const [scroll, setScroll] = useState<boolean>(false);

  const trackNavClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    trackButtonPress({
      source_type: 'navigation',
      source: e.currentTarget.dataset.source,
      step_value: 'zipcode'
    })
  }

  useEffect(() => {
    const scrollListener = () => {
      setScroll(window.scrollY > 50)
    }

    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, []);

  return (
    <div className={`bg-cashew z-50 fixed sm:relative w-full lg:max-w-7xl px-7 xl:px-0 pt-6 pb-6 m-auto flex justify-between items-center ${scroll ? 'border-b border-kale' : ''}`}>
      <div>
        <a href={currentHost}>
          <img src={InstaIconKale} alt="carrot" width="140" height="auto" />
        </a>
      </div>
      <nav>
        <div>
          <button
            id="navbar-download-link"
            onClick={onClickExpandSignupForm}
            data-source="download_the_app"
            className="bg-kale rounded-full px-6 py-2.5 text-cashew sm-bg-cashew sm:text-kale sm:p-0 sm:rounded-none flex items-center"
          >
            <ReactSVG path={ShopperIcon} className="hidden sm:block" svgStyle={{ width: 34, height: 34 }} />
            <span className="text-sm font-medium">Sign up</span>
          </button>
        </div>
      </nav>
    </div>
  )
}
