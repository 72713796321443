import React, { useState, useRef } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'
import { postForm } from '../../../../../api_utils/utils'
import { PhoneInputField } from '../Inputs/PhoneInput'
import SuccessCheck from '../../../../../../assets/images/applicants/landing_page/success-check.svg'
import appStore from '../../../../../../assets/images/applicants/landing_page/apple-icon.svg'
import googlePlay from '../../../../../../assets/images/applicants/landing_page/google-play-icon.svg'
import appStoreKale from '../../../../../../assets/images/applicants/landing_page/apple-icon-kale.svg'
import googlePlayKale from '../../../../../../assets/images/applicants/landing_page/google-play-icon-kale.svg'

export const AppIcons = ({
  content: { downloadIos, downloadAndroid, ios, android }, color
}: {
  content: { downloadIos: string; downloadAndroid: string; ios: string; android: string }, color: string
}) => {
  return (
    <div className="flex md:hidden text-xs pt-2">
      <a href="https://instashopper.app.link/appdownload" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center mr-4">
        <img src={color === 'kale' ? appStoreKale : appStore} alt={downloadIos} loading="lazy" className="inline" />
        <span className="pl-1.5 underline-offset-3 underline">{ios}</span>
      </a>
      <a href="https://instashopper.app.link/appdownload" target="_blank" rel="noopener noreferrer" className="flex justify-center items-center">
        <img src={color === 'kale' ? googlePlayKale : googlePlay} alt={downloadAndroid} loading="lazy" className="inline" />
        <span className="pl-1.5 underline-offset-3 underline">{android}</span>
      </a>
    </div>
  )
}

export const DownloadAppForm = ({
  sendAppLinkUrl,
  invalidPhoneMessage,
  successMessage,
  requiredMessage,
  autofillPhone,
  primaryColorCashew,
}: {
  sendAppLinkUrl: string;
  invalidPhoneMessage: string;
  successMessage: string;
  requiredMessage: string;
  autofillPhone?: string;
  primaryColorCashew?: boolean;
}) => {
  const {
    showCountryCode,
    currentCountryCode,
    currentCountry,
    landingPageStrings,
  } = window.gon

  const [phoneNumber, setPhoneNumber] = useState<string>(autofillPhone || '')
  const [successMsg, setSuccessMsg] = useState<string | undefined>()
  const [errorMsg, setErrorMsg] = useState<string | undefined>()
  const [isValid, setIsValid] = useState<boolean>(phoneNumber !== '' || false)
  const formRef = useRef()

  const onSubmit = (e, val) => {
    e.preventDefault()
    setSuccessMsg(undefined)
    setErrorMsg(undefined)

    if (!isValid) {
      return setErrorMsg(invalidPhoneMessage)
    }

    window.dataLayer?.push({
      source_type: 'sms_link_submit',
      source: {
        Url: window.location.pathname,
        Phone: formatPhoneNumber(val)
      },
      step_value: 'signup'
    })

    const formPhone = new FormData(formRef.current)
    return postForm(sendAppLinkUrl, formPhone, { maxRedirects: 0 })
      .then((response) => {
        setSuccessMsg(successMessage)
        setErrorMsg(undefined)
        setPhoneNumber('')
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          error.response.data.phone && setErrorMsg(error.response.data.phone[0])
        } else {
          setErrorMsg(invalidPhoneMessage)
        }
      })
  }

  return (
    <>
      <AppIcons content={landingPageStrings.downloadApp} color="cashew" />
      <form onSubmit={(e) => onSubmit(e, phoneNumber)} ref={formRef} className="hidden md:flex pt-4">
        <div className="pr-2">
          {showCountryCode && (
            <input
              name="country_code"
              value={`+${currentCountryCode}`}
              className={`py-3 pl-7 pr-14 mr-3 mb-3 border rounded-full ${primaryColorCashew ? 'border-kale text-kale placeholder-kale bg-cashew' : 'border-cashew text-cashew placeholder-cashew bg-kale'}`}
              disabled
            />
          )}
          <PhoneInputField
            className={`pl-7 pr-14 mr-3 mb-3 border rounded-full ${primaryColorCashew ? 'border-kale text-kale placeholder-kale bg-cashew' : 'border-cashew text-cashew placeholder-cashew bg-kale'}`}
            type="tel"
            name="phone"
            placeholder={landingPageStrings.downloadApp.placeholderShoppers}
            value={phoneNumber}
            setValue={setPhoneNumber}
            invalidPhoneMessage={invalidPhoneMessage}
            validationMsg={errorMsg}
            requiredMessage={requiredMessage}
            required
            country={currentCountry.toUpperCase()}
            setIsValid={setIsValid}
          />
          {successMsg && (
            <p className="flex text-sm py-2">
              <img src={SuccessCheck} alt="success check" className="pr-1" />
              {successMsg}
            </p>
          )}
        </div>
        <div className="columns">
          <div className="column">
            <button
              className={`rounded-full px-7 py-3 ${primaryColorCashew ? 'bg-kale text-cashew' : 'bg-cashew text-kale'}`}
              type="submit"
            >
              {landingPageStrings.downloadApp.ctaShoppers}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
