import React, { useState, useEffect } from 'react'
import { SHOPPERS_FORM_ID } from '../shared/utils/SignupForm'

export const useOpenSignup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openSignupForm = () => {
    setIsOpen(true)
    const form = document.getElementById(SHOPPERS_FORM_ID)
    form.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  return { isOpen, openSignupForm }
}
