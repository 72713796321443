import React, { useEffect } from 'react'
import { LandingPagesLayout } from '../../shared/components/LandingPagesLayout'
import { BecomeAShopper } from '../../shared/components/BecomeAShopper';
import { SignUpHero } from '../../shared/components/SignUpHero';
import { HowInstacartWorks } from '../../shared/components/HowInstacartWorks';
import { StoresYouCanShop } from '../../shared/components/StoresYouCanShop';
import { WhyShoppersChooseInstacart } from '../../shared/components/WhyShoppersChooseInstacart';
import { CommonQuestions } from '../../shared/components/CommonQuestions';
import { ShoppersMakeIt } from '../../shared/components/ShoppersMakeIt';
import { useViewport } from '../../hooks/useViewport';
import { useOpenSignup } from '../../hooks/useOpenSignup';

export const Shoppers = () => {
  const {
    landingPageStrings,
    currentHost,
    topCities,
    privacyChoicesEnabled,
    locale,
    defaultCountry,
    currentCountry,
    currentCountrySlug,
    nearmeFormAction,
    jobsPageStrings,
    availableCountries,
    countryPlaceholders,
    shopperJobsUrl,
    signupUrl,
    signupFormSubmitUrl,
    currentZip,
    referralCode,
    states,
    stores,
    currentCity,
    sendAppLinkUrl
  } = window.gon
  const { isOpen, openSignupForm } = useOpenSignup()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const showSignupForm = params.get('show_signup_form') === 'true';
    if (showSignupForm) {
      openSignupForm()
    }
  }, [])

  return (
    <LandingPagesLayout
      signupLink={openSignupForm}
      footerContent={landingPageStrings.footer}
      currentHost={currentHost}
      currentCountry={currentCountry}
      locale={locale}
      topCities={topCities}
      privacyChoicesEnabled={privacyChoicesEnabled}
      className="lg:max-w-full"
    >
      <SignUpHero
        title={landingPageStrings.hero.title}
        subtitle={landingPageStrings.hero.subtitle}
        content={landingPageStrings.signupForm}
        referralCode={referralCode}
        currentCity={currentCity}
        currentCountrySlug={currentCountrySlug}
        currentCountry={currentCountry}
        countryPlaceholders={countryPlaceholders}
        currentZip={currentZip}
        signupFormSubmitUrl={signupFormSubmitUrl}
        sendAppLinkUrl={sendAppLinkUrl}
        signUpSteps={jobsPageStrings.signUpSteps}
        isFormOpen={isOpen}
      />
      <div>
        <StoresYouCanShop
          title={landingPageStrings.storesYouCanShop.title}
          subtitle={landingPageStrings.storesYouCanShop.subtitle}
          cta={landingPageStrings.storesYouCanShop.cta}
          subtext={landingPageStrings.storesYouCanShop.subtext}
          stores={stores}
          search
        />
      </div>
      <div className="bg-kale px-7 xl:px-0">
        <HowInstacartWorks title={jobsPageStrings.howInstacartWorks.title} items={jobsPageStrings.howInstacartWorks.items} />
      </div>
      <WhyShoppersChooseInstacart
        title={jobsPageStrings.whyShoppersChooseInstacart.title}
        description={jobsPageStrings.whyShoppersChooseInstacart.description}
        shoppers={jobsPageStrings.whyShoppersChooseInstacart.shoppers}
      />
      <div className="bg-kale px-7 xl:px-0">
        <ShoppersMakeIt
          title={jobsPageStrings.shoppersMakeIt.title}
          description={jobsPageStrings.shoppersMakeIt.description}
          shopperChat={jobsPageStrings.shoppersMakeIt.shopperChat}
        />
      </div>
      <CommonQuestions title={jobsPageStrings.commonQuestions.title} questions={jobsPageStrings.commonQuestions.questions} />
      <div className="bg-kale-dark px-7 xl:px-0">
        <BecomeAShopper
          title={jobsPageStrings.becomeAShopper.title}
          subtitle={jobsPageStrings.becomeAShopper.subtitle}
          cta={jobsPageStrings.becomeAShopper.cta}
          signupUrl={signupUrl}
        />
      </div>
    </LandingPagesLayout>
  )
}
