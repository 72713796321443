import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const LeftArrow = ({ fill }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
    <circle cx="30" cy="30" r="29" stroke={fill} strokeWidth="2" />
    <g clipPath="url(#clip0_24331_15837)">
      <path d="M21.3413 23.5323L16 30.0202L21.2942 36.4273C21.4207 36.6026 21.5865 36.7457 21.7784 36.8452C21.9702 36.9446 22.1827 36.9977 22.3988 37C22.7757 36.9998 23.1387 36.8579 23.4159 36.6025C23.5458 36.4772 23.6482 36.3263 23.7166 36.1593C23.785 35.9922 23.818 35.8128 23.8133 35.6323C23.8143 35.4314 23.7713 35.2326 23.6875 35.0499C23.6038 34.8672 23.4811 34.705 23.3282 34.5746L18.9973 31.0375L44 31.0375V29.0501L18.9973 29.0501L23.3282 25.4726C23.4753 25.3374 23.594 25.1742 23.6773 24.9926C23.7606 24.811 23.8069 24.6145 23.8133 24.4148C23.8174 24.2274 23.7843 24.0411 23.7161 23.8665C23.6479 23.6919 23.5459 23.5325 23.4159 23.3975C23.1271 23.1612 22.7712 23.0221 22.3988 23C22.1936 23.0023 21.9917 23.0515 21.8084 23.1437C21.6252 23.236 21.4654 23.3688 21.3413 23.5323Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_24331_15837">
        <rect width="28" height="14" fill="white" transform="matrix(-1 0 0 1 44 23)" />
      </clipPath>
    </defs>
  </svg>
)

const RightArrow = ({ fill }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2">
    <circle cx="30" cy="30" r="29" stroke={fill} strokeWidth="2" />
    <g clipPath="url(#clip0_24331_15841)">
      <path d="M38.6587 23.5323L44 30.0202L38.7058 36.4273C38.5793 36.6026 38.4135 36.7457 38.2216 36.8452C38.0298 36.9446 37.8173 36.9977 37.6012 37C37.2243 36.9998 36.8613 36.8579 36.5841 36.6025C36.4542 36.4772 36.3518 36.3263 36.2834 36.1593C36.215 35.9922 36.182 35.8128 36.1867 35.6323C36.1857 35.4314 36.2287 35.2326 36.3125 35.0499C36.3962 34.8672 36.5189 34.705 36.6718 34.5746L41.0027 31.0375L16 31.0375V29.0501L41.0027 29.0501L36.6718 25.4726C36.5247 25.3374 36.406 25.1742 36.3227 24.9926C36.2394 24.811 36.1931 24.6145 36.1867 24.4148C36.1826 24.2274 36.2157 24.0411 36.2839 23.8665C36.3521 23.6919 36.4541 23.5325 36.5841 23.3975C36.8729 23.1612 37.2288 23.0221 37.6012 23C37.8064 23.0023 38.0083 23.0515 38.1916 23.1437C38.3748 23.236 38.5346 23.3688 38.6587 23.5323Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_24331_15841">
        <rect width="28" height="14" fill="white" transform="translate(16 23)" />
      </clipPath>
    </defs>
  </svg>
)

const HowInstacartWorksSlide = ({ item, index, iss }) => {
  const [image, setImage] = useState(null)

  const fetchImage = async () => {
    const img = await import(`../../../../../assets/images/applicants/landing_page/how-${iss ? 'iss' : 'fss'}-works-${index + 1}.webp`);
    setImage(img.default)
  }
  useEffect(() => {
    fetchImage()
  }, [index])
  return (
    <div>
      <div className="mb-10">
        {image && <img src={image} height="412" width="360" alt={item} />}
      </div>
      <p className="text-2xl max-w-xs text-cashew" dangerouslySetInnerHTML={{ __html: item }} />
    </div>
  )
}

export const HowInstacartWorks = (
  { title,
    items,
    iss
  }:
  {
    title: string;
    items: Array<string>;
    iss?: boolean
  }
) => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  return (
    <section className="lg:max-w-7xl py-14 lg:py-32 mx-auto flex flex-col">
      <div className="container flex justify-between mb-14 mx-auto">
        <h1 className="text-hxl md:text-h2xl font-heading text-cashew">
          {title}
        </h1>
        <div className="hidden md:flex flex-row ">
          <button
            disabled={carouselIndex === 0}
            className={carouselIndex === 0 ? 'opacity-40' : ''}
            onClick={() => carouselRef.current?.slickPrev()}
          >
            <LeftArrow fill="#FAF1E5" />
          </button>
          <button
            disabled={carouselIndex === 3}
            className={carouselIndex === 3 ? 'opacity-40' : ''}
            onClick={() => carouselRef.current?.slickNext()}
          >
            <RightArrow fill="#FAF1E5" />
          </button>
        </div>
      </div>
      <div>
        <Slider
          ref={(carousel) => { carouselRef.current = carousel }}
          arrows={false}
          dots={false}
          infinite={false}
          slidesToShow={3}
          slidesToScroll={1}
          beforeChange={(_current: number, next: number) => setCarouselIndex(next)}
          responsive={[
              {
                breakpoint: 767,
                settings: {
                  arrows: false,
                  dots: true,
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '20px',
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 3,
                },
              },
            ]}
        >
          {items.map((item, index) =>
            <HowInstacartWorksSlide item={item} index={index} key={index} iss={iss} />
          )}
        </Slider>
      </div>
    </section>
  );
}
