import React, { useState, useEffect } from 'react'
import InputMask from 'react-input-mask';
import { ZipInputTypes } from './types'

export const ZipInput = ({
  tabIndex,
  className,
  label,
  name,
  value,
  required,
  onChange,
  currentZipPlaceholder,
  country,
  zipCodeMask,
  validationMsg
}: ZipInputTypes) => {
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(validationMsg)

  useEffect(() => {
    setValidationErrorMessage(validationMsg)
  }, [validationMsg, country])


  const handleBlur = () => {
    if ((value.replace(/_/g, '').trim().length === 0) && required) {
      setValidationErrorMessage('Required field')
      return
    }

    if (value.includes('_')) {
      setValidationErrorMessage(`Invalid ${currentZipPlaceholder}`)
      return
    }

    setValidationErrorMessage(undefined)
  }

  return (
    <div className="flex flex-col mb-12">
      {label && <label htmlFor={name} className="text-base font-medium">{label}</label>}
      <InputMask
        tabIndex={tabIndex ?? 0}
        className={`${className}`}
        type="text"
        placeholder={currentZipPlaceholder}
        mask={zipCodeMask}
        onBlur={handleBlur}
        onChange={onChange}
        value={value}
        name={name}
      />

      {validationErrorMessage && (
        <p className="text-cinnamon text-base py-4">{validationErrorMessage}</p>
      )}
    </div>
  )
}
