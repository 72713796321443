/* global analytics_track */

// Event names
const BUTTON_PRESS = 'applicants.button_press'
const FORM_SUBMIT = 'applicants.form_submit'
const FORM_SUCCESS = 'applicants.form_success'
const FORM_ERROR = 'applicants.form_error_client'

const platform = () => {
  if (
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      navigator.userAgent.toLowerCase()
    )
  ) {
    return 'mobile_web'
  }
  return 'web'
}

export const trackEvent = (eventName, attributes) => {
  analytics_track(eventName, {
    platform: platform(),
    ...attributes
  })
}

export const trackButtonPress = (attributes) => {
  trackEvent(BUTTON_PRESS, attributes)
}

export const trackFormSubmit = (attributes) => {
  trackEvent(FORM_SUBMIT, attributes)
}

export const trackFormSuccess = (attributes) => {
  trackEvent(FORM_SUCCESS, attributes)
}

export const trackFormSubmitAndSuccess = (attributes) => {
  trackEvent(FORM_SUBMIT, attributes)
  trackEvent(FORM_SUCCESS, attributes)
}

export const trackFormError = (attributes) => {
  trackEvent(FORM_ERROR, attributes)
}
